<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理> <b>{{ id ? '修改项目' : '新建项目' }}</b></span>
            <div class="flex-center" @click="backPage"><i class="iconfont iconjiantou-copy-copy"></i>返回</div>
        </div>
        <div class="add-info">
            <span>{{ id ? '修改项目' : '新建项目' }}</span>
            <el-form ref="ruleForm" :model="ruleForm"
                     :rules="rules" class="form flex flex-direction margin-top" labelWidth="230px">
                <div class="classify-title">基础信息</div>
                <el-form-item label="项目类别" prop="cid">
                    <el-select v-model="ruleForm.cid " placeholder="请选择项目类别" @change="changeType">
                        <el-option
                            v-for="item in classifyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="`${$getTitleByType('会议名称',ruleForm.cid)}`"
                              :rules="[{required: true, message: `请输入${$getTitleByType('会议名称',ruleForm.cid)}`, trigger: 'blur'}]"
                              prop="name">
                    <el-input v-model="ruleForm.name"
                              :placeholder="`请输入${$getTitleByType('会议名称',ruleForm.cid)}`"></el-input>
                </el-form-item>

                <el-form-item label="所属学会" prop="society">
                    <el-select v-model="ruleForm.society" multiple placeholder="请选择所属学会">
                        <el-option
                            v-for="item in societyOptions"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item :label="`${$getTitleByType('大会主席',ruleForm.cid)}`"
                              :rules="[{required: true, message: `请输入${$getTitleByType('大会主席',ruleForm.cid)}`, trigger: 'blur'}]"
                              prop="chairman">
                    <el-input v-model="ruleForm.chairman"
                              :placeholder="`请输入${$getTitleByType('大会主席',ruleForm.cid)}`"></el-input>
                </el-form-item>
                <el-form-item :label="`${$getTitleByType('会议日期',ruleForm.cid)}`"
                              :rules="[{required: true, message: `请选择${$getTitleByType('会议日期',ruleForm.cid)}`, trigger: 'change'}]"
                              prop="start_end_time">
                    <el-date-picker
                        v-model="ruleForm.start_end_time"
                        end-placeholder="结束日期"
                        start-placeholder="开始日期"
                        type="daterange"
                        value-format="timestamp">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="ruleForm.desc" :rows="5" placeholder="请输入备注" resize="none"
                              type="textarea"></el-input>
                </el-form-item>
                <div class="classify-title">补充信息</div>
                <el-form-item :label="`${$getTitleByType('会议',ruleForm.cid)}类型`"
                              :rules="[{required: true, message: `请选择${$getTitleByType('会议',ruleForm.cid)}类型`, trigger: 'change'}]">
                    <el-select v-model="ruleForm.meet_type" :placeholder="`请选择${$getTitleByType('会议',ruleForm.cid)}类型`">
                        <el-option
                            v-for="item in projectTypes.filter(type=>{ return type.type === $isProjectType(ruleForm.cid) })"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <div v-if="$isProjectTypeD(ruleForm.cid)">
                    <el-form-item v-if="ruleForm.meet_type === 2 && $isProjectTypeD(ruleForm.cid)" label="系列会议场次"
                                  prop="type_series">
                        <el-input v-model="ruleForm.type_series" placeholder="请输入场次"></el-input>
                    </el-form-item>
                </div>

                <el-form-item label="所在单位" prop="company">
                    <el-input v-model="ruleForm.company" placeholder="请输入所在单位"></el-input>
                </el-form-item>

<!--                <el-form-item :label="`${$getTitleByType('会议',ruleForm.cid)}地点`"-->
<!--                              :rules="[{required: true, message: `请输入${$getTitleByType('会议',ruleForm.cid)}地点`, trigger: 'blur'}]"-->
<!--                              prop="name">-->
<!--                    <el-input v-model="ruleForm.name"-->
<!--                              :placeholder="`请输入${$getTitleByType('会议',ruleForm.cid)}地点`"></el-input>-->
<!--                </el-form-item>-->

                <el-form-item :label="`${$getTitleByType('会议',ruleForm.cid)}地点`"
                              :rules="[{required: true, message: `请输入${$getTitleByType('会议',ruleForm.cid)}地点`, trigger: 'blur'}]"
                              prop="address">
                    <el-input v-model="ruleForm.address" :placeholder="`请输入${$getTitleByType('会议',ruleForm.cid)}地点`"></el-input>
                </el-form-item>


                <div v-if="$isProjectTypeD(ruleForm.cid)">
                    <el-form-item :label="`${$getTitleByType('会议',ruleForm.cid)}形式`"
                                  :rules="[{required: true, message: `请选择${$getTitleByType('会议',ruleForm.cid)}形式`, trigger: 'change'}]">
                        <el-select v-model="ruleForm.meet_form" :placeholder="`请选择${$getTitleByType('会议',ruleForm.cid)}形式`">
                            <el-option
                                v-for="item in projectFroms"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item v-if="ruleForm.meet_form === 4" :label="`其他${$getTitleByType('会议',ruleForm.cid)}形式`" prop="meet_info"
                                  :rules="[{required: true, message: `请输入其他${$getTitleByType('会议',ruleForm.cid)}形式`, trigger: 'blur'}]">
                        <el-input v-model="ruleForm.meet_info" :placeholder="`请输入其他${$getTitleByType('会议',ruleForm.cid)}形式`"></el-input>
                    </el-form-item>
                </div>
                <el-form-item v-else :label="`${$getTitleByType('会议',ruleForm.cid)}形式`" prop="meet_info"
                              :rules="[{required: true, message: `请输入${$getTitleByType('会议',ruleForm.cid)}形式`, trigger: 'blur'}]">
                    <el-input v-model="ruleForm.meet_info" :placeholder="`请输入${$getTitleByType('会议',ruleForm.cid)}形式`"></el-input>
                </el-form-item>

                <div v-if="$isProjectTypeD(ruleForm.cid)">
                    <el-form-item label="线上平台" prop="platform">
                        <el-input v-model="ruleForm.platform" placeholder="请输入线上平台"></el-input>
                    </el-form-item>
                </div>
                <el-form-item key="if_union" label="是否联合主办方" required>
                    <el-radio-group v-model="ruleForm.if_union">
                        <el-radio :label="1">否</el-radio>
                        <el-radio :label="2">是</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item v-if="ruleForm.if_union === 2" label="其他主办方" prop="sponsor">
                    <el-input v-model="ruleForm.sponsor" placeholder="请输入其他主办方"></el-input>
                </el-form-item>
                <el-form-item v-if="$isProjectTypeD(ruleForm.cid)" :label="`${$getTitleByType('项目单场规模',ruleForm.cid)}`"
                              :rules="[{required: true, message: `请选择${$getTitleByType('项目单场规模',ruleForm.cid)}`, trigger: 'blur'}]"
                              prop="scale">
                    <el-select v-model="ruleForm.scale" :placeholder="`请选择${$getTitleByType('项目单场规模',ruleForm.cid)}`">
                        <el-option
                            v-for="item in projectScales"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>
                </el-form-item>

                <div v-if="$isProjectTypeS(ruleForm.cid)">
                    <el-form-item label="组长单位" prop="group_company">
                        <el-input v-model="ruleForm.group_company" placeholder="请输入组长单位"></el-input>
                    </el-form-item>
                    <el-form-item label="中心数量类型" prop="center_num">
                        <el-select v-model="ruleForm.center_num" placeholder="请选择中心数量类型">
                            <el-option
                                v-for="item in projectNums"
                                :key="item.key"
                                :label="item.value"
                                :value="item.key">
                            </el-option>
                        </el-select>
                    </el-form-item>
<!--                    <el-form-item v-if="ruleForm.center_num === 2" label="中心数量" prop="center_num_other">-->
<!--                        <el-input v-model="ruleForm.center_num_other" placeholder="请输入中心数量"></el-input>-->
<!--                    </el-form-item>-->
                    <el-form-item key="is_ethical_approval" label="是否需要伦理审批" required>
                        <el-radio-group v-model="ruleForm.is_ethical_approval">
                            <el-radio :label="1">需要</el-radio>
                            <el-radio :label="0">不需要</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item key="is_human_heritage_approval" label="是否需要人遗办审批" required>
                        <el-radio-group v-model="ruleForm.is_human_heritage_approval">
                            <el-radio :label="1">需要</el-radio>
                            <el-radio :label="0">不需要</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item key="is_test_insurance" label="是否购买试验保险" required>
                        <el-radio-group v-model="ruleForm.is_test_insurance">
                            <el-radio :label="1">需要购买</el-radio>
                            <el-radio :label="0">不需要购买</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <el-form-item :label="`${$getTitleByType('是否需要委托会议执行公司',ruleForm.cid)}`" required>
                    <el-radio-group v-model="ruleForm.if_entrust">
                        <el-radio :label="1">需要</el-radio>
                        <el-radio :label="2">不需要</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="申请日期" prop="time">
                    <el-date-picker
                        v-model="ruleForm.time"
                        placeholder="请选择申请日期"
                        type="date"
                        value-format="timestamp">
                    </el-date-picker>
                </el-form-item>
                <el-button class="submit self-center" type="primary" @click="submitForm('ruleForm')">提交</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>

import {projectFroms, projectNums, projectScales, projectTypes} from "@/tools";

export default {
    components: {},
    name: "add",
    data() {
        return {
            id: '',
            ruleForm: {
                //基础信息
                name: '',
                society: [],              //  学会id
                cid: '',                  //  类别id
                chairman: '',             //  大会主席
                start_end_time: [],       //  开始结束时间
                desc: '',                 //  简介

                //项目
                meet_type: '',
                type_series: '',
                company: '',
                address: '',
                meet_form: '',
                meet_info: '',
                platform: '',
                if_union: 1,
                sponsor: '',
                scale: '',
                if_entrust: 1,
                //科研

                group_company: '',
                center_num: '',
                center_num_other: '',
                is_ethical_approval: 1,
                is_human_heritage_approval: 1,
                is_test_insurance: 1,

                time: new Date().getTime(),
            },
            rules: {
                cid: [
                    {required: true, message: '请选择项目分类', trigger: 'blur'}
                ],
                // name: [
                //     {required: true, message: '请输入项目名称', trigger: 'blur'}
                // ],
                society: [
                    {type: 'array', required: true, message: '请选择学会', trigger: 'blur'}
                ],
                meet_type: [
                    {required: true, message: '请选择项目类型', trigger: 'blur'}
                ],
                type_series: [
                    {required: true, message: '请输入场次', trigger: 'blur'}
                ],
                company: [
                    {required: true, message: '请输入所在单位', trigger: 'blur'}
                ],
                // address: [
                //     {required: true, message: '请输入所在地点', trigger: 'blur'}
                // ],
                meet_form: [
                    {required: true, message: '请选择项目形式', trigger: 'blur'}
                ],
                // meet_info: [
                //     {required: true, message: '请输入其他会议形式', trigger: 'blur'}
                // ],
                platform: [
                    {required: true, message: '请输入线上平台', trigger: 'blur'}
                ],
                sponsor: [
                    {required: true, message: '请输入其他主办方', trigger: 'blur'}
                ],
                // scale: [
                //     {required: true, message: '请选择项目单场规模', trigger: 'blur'}
                // ],


                group_company: [
                    {required: true, message: '请输入组长单位', trigger: 'blur'}
                ],
                center_num: [
                    {required: true, message: '请选择中心数量类型', trigger: 'blur'}
                ],
                center_num_other: [
                    {required: true, message: '请输入中心数量', trigger: 'blur'}
                ],
                time: [
                    {type: 'date', required: true, message: '请选择申请日期', trigger: 'change'}
                ],
            },
            societyOptions: [],       //  学会
            classifyOptions: [],      //  类别
            projectTypes: projectTypes,      //  项目类型
            projectFroms: projectFroms,      //  项目形式
            projectScales: projectScales,      //  项目单场规模
            projectNums: projectNums,      //  中心数量
            requestState: false,
            sign_code: '',
        }
    },
    created() {
        this.$emit('setIndex', [1, 1])
        this.init()
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.sign_code = this.$route.query.sign_code
            this.getDetail()
        }
    },
    methods: {
        //  获取项目信息
        getDetail() {
            this.$api.apiContent.getApProjectDetail({
                id: this.id,
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                sign: this.$tools.SHA256({
                    id: this.id,
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                }, this.$store.state.user.key)
            }).then(res => {
                this.ruleForm.name = res.data.name
                this.ruleForm.society = res.data.sid.replace(/(^,)|(,$)/g, '').split(',').map(item => {
                    return Number(item)
                })             //  学会id
                this.ruleForm.cid = res.data.cid                     //  类别id
                this.ruleForm.chairman = res.data.chairman           //  大会主席
                this.ruleForm.start_end_time = [res.data.start_time * 1000, res.data.end_time * 1000]                    //  开始结束时间
                this.ruleForm.desc = res.data.desc                   //  简介

                this.ruleForm.meet_type = res.data.infor.meet_type
                this.ruleForm.type_series = res.data.infor.type_series
                this.ruleForm.company = res.data.infor.company
                this.ruleForm.address = res.data.infor.address
                this.ruleForm.meet_form = res.data.infor.meet_form
                this.ruleForm.meet_info = res.data.infor.meet_info
                this.ruleForm.platform = res.data.infor.platform
                this.ruleForm.if_union = res.data.infor.if_union
                this.ruleForm.sponsor = res.data.infor.sponsor
                this.ruleForm.scale = res.data.infor.scale
                this.ruleForm.if_entrust = res.data.infor.if_entrust
                this.ruleForm.time = res.data.infor.sign_time * 1000

                this.ruleForm.group_company = res.data.infor.group_company
                this.ruleForm.center_num = res.data.infor.center_num === 1 ? 1 : 2
                this.ruleForm.center_num_other = res.data.infor.center_num === 1 ? '' : res.data.infor.center_num
                this.ruleForm.is_ethical_approval = res.data.infor.is_ethical_approval
                this.ruleForm.is_human_heritage_approval = res.data.infor.is_human_heritage_approval
                this.ruleForm.is_test_insurance = res.data.infor.is_test_insurance


                if (this.sign_code) this.id = ''
            }).catch(e => {
                console.log(e)
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addProject();
                }
            });
        },
        //  提交修改
        async addProject() {
            if (this.requestState) return
            this.requestState = true
            //添加
            if (this.id) {
                this.$api.apiContent.projectEdit({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    id: this.id,
                    name: this.ruleForm.name,
                    sid: this.ruleForm.society.join(','),
                    admin_id: this.$store.state.user.mid,
                    cid: this.ruleForm.cid,
                    chairman: this.ruleForm.chairman,
                    desc: this.ruleForm.desc,
                    sign_code: this.sign_code,
                    start_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[0]), 6),
                    end_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[1]), 6),

                    company: this.ruleForm.company,
                    address: this.ruleForm.address,
                    meet_type: this.ruleForm.meet_type,
                    type_series: this.ruleForm.type_series,
                    meet_form: this.ruleForm.meet_form,
                    meet_info: this.ruleForm.meet_info,
                    platform: this.ruleForm.platform,
                    scale: this.ruleForm.scale,
                    sponsor: this.ruleForm.sponsor,
                    if_union: this.ruleForm.if_union,
                    if_entrust: this.ruleForm.if_entrust,
                    sign_time: Number(this.ruleForm.time / 1000).toFixed(0),

                    group_company: this.ruleForm.group_company,
                    center_num: this.ruleForm.center_num === 1 ? this.ruleForm.center_num : this.ruleForm.center_num_other,
                    is_ethical_approval: this.ruleForm.is_ethical_approval,
                    is_human_heritage_approval: this.ruleForm.is_human_heritage_approval,
                    is_test_insurance: this.ruleForm.is_test_insurance,

                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        id: this.id,
                        name: this.ruleForm.name,
                        sid: this.ruleForm.society.join(','),
                        admin_id: this.$store.state.user.mid,
                        cid: this.ruleForm.cid,
                        chairman: this.ruleForm.chairman,
                        desc: this.ruleForm.desc,
                        sign_code: this.sign_code,
                        start_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[0]), 6),
                        end_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[1]), 6),

                        company: this.ruleForm.company,
                        address: this.ruleForm.address,
                        meet_type: this.ruleForm.meet_type,
                        type_series: this.ruleForm.type_series,
                        meet_form: this.ruleForm.meet_form,
                        meet_info: this.ruleForm.meet_info,
                        platform: this.ruleForm.platform,
                        scale: this.ruleForm.scale,
                        sponsor: this.ruleForm.sponsor,
                        if_union: this.ruleForm.if_union,
                        if_entrust: this.ruleForm.if_entrust,
                        sign_time: Number(this.ruleForm.time / 1000).toFixed(0),
                        group_company: this.ruleForm.group_company,
                        center_num: this.ruleForm.center_num === 1 ? this.ruleForm.center_num : this.ruleForm.center_num_other,
                        is_ethical_approval: this.ruleForm.is_ethical_approval,
                        is_human_heritage_approval: this.ruleForm.is_human_heritage_approval,
                        is_test_insurance: this.ruleForm.is_test_insurance,
                    }, this.$store.state.user.key)
                }).then(res => {
                    this.$message.success(res.msg)
                    if (this.sign_code) {
                        this.$router.push({
                            name: "projectDetail",
                            query: {id: res.data.id, type: 1},
                        });
                    } else {
                        this.backPage()
                    }
                }).catch(e => {
                    this.requestState = false
                    this.$message.error(e.msg)
                })
            } else {
                this.$api.apiContent.projectAdd({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                    name: this.ruleForm.name,
                    sid: this.ruleForm.society.join(','),
                    admin_id: this.$store.state.user.mid,
                    cid: this.ruleForm.cid,
                    chairman: this.ruleForm.chairman,
                    desc: this.ruleForm.desc,
                    sign_code: this.sign_code,
                    start_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[0]), 6),
                    end_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[1]), 6),

                    company: this.ruleForm.company,
                    address: this.ruleForm.address,
                    meet_type: this.ruleForm.meet_type,
                    type_series: this.ruleForm.type_series,
                    meet_form: this.ruleForm.meet_form,
                    meet_info: this.ruleForm.meet_info,
                    platform: this.ruleForm.platform,
                    scale: this.ruleForm.scale,
                    sponsor: this.ruleForm.sponsor,
                    if_union: this.ruleForm.if_union,
                    if_entrust: this.ruleForm.if_entrust,
                    sign_time: Number(this.ruleForm.time / 1000).toFixed(0),
                    group_company: this.ruleForm.group_company,
                    center_num: this.ruleForm.center_num === 1 ? this.ruleForm.center_num : this.ruleForm.center_num_other,
                    is_ethical_approval: this.ruleForm.is_ethical_approval,
                    is_human_heritage_approval: this.ruleForm.is_human_heritage_approval,
                    is_test_insurance: this.ruleForm.is_test_insurance,

                    sign: this.$tools.SHA256({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        name: this.ruleForm.name,
                        sid: this.ruleForm.society.join(','),
                        admin_id: this.$store.state.user.mid,
                        cid: this.ruleForm.cid,
                        chairman: this.ruleForm.chairman,
                        desc: this.ruleForm.desc,
                        sign_code: this.sign_code,
                        start_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[0]), 6),
                        end_time: this.$tools.ZHDate(new Date(this.ruleForm.start_end_time[1]), 6),

                        company: this.ruleForm.company,
                        address: this.ruleForm.address,
                        meet_type: this.ruleForm.meet_type,
                        type_series: this.ruleForm.type_series,
                        meet_form: this.ruleForm.meet_form,
                        meet_info: this.ruleForm.meet_info,
                        platform: this.ruleForm.platform,
                        scale: this.ruleForm.scale,
                        sponsor: this.ruleForm.sponsor,
                        if_union: this.ruleForm.if_union,
                        if_entrust: this.ruleForm.if_entrust,
                        sign_time: Number(this.ruleForm.time / 1000).toFixed(0),
                        group_company: this.ruleForm.group_company,
                        center_num: this.ruleForm.center_num === 1 ? this.ruleForm.center_num : this.ruleForm.center_num_other,
                        is_ethical_approval: this.ruleForm.is_ethical_approval,
                        is_human_heritage_approval: this.ruleForm.is_human_heritage_approval,
                        is_test_insurance: this.ruleForm.is_test_insurance,

                    }, this.$store.state.user.key)
                }).then(res => {
                    this.$message.success(res.msg)
                    this.$router.push({
                        name: "projectDetail",
                        query: {id: res.data.id, type: 1},
                    });
                }).catch(e => {
                    this.requestState = false
                    this.$message.error(e.msg)
                })
            }

        },
        //  页面加载时请求
        init() {
            // 获取学会列表
            this.$api.apiContent.getSociety({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                }, this.$store.state.user.key)
            }).then(res => {
                this.societyOptions = res.data
            }).catch(() => {
                this.societyOptions = []
            })
            //  获取项目分类
            this.$api.apiContent.getProjectCat({
                token: this.$store.state.user.token,
                timestamp: this.$tools.requestTime(),
                sign: this.$tools.SHA256({
                    token: this.$store.state.user.token,
                    timestamp: this.$tools.requestTime(),
                }, this.$store.state.user.key)
            }).then(res => {
                this.classifyOptions = res.data
            }).catch(() => {
                this.classifyOptions = []
            })
        },
        backPage() {
            this.$tools.goBack()
        },
        changeType() {
            this.ruleForm.meet_type = ''
            this.$refs.ruleForm.clearValidate()
        }
    }
}
</script>

<style lang="scss" scoped>
.page-box {
    .page-title {
        height: 40px;
        margin-bottom: 10px;

        span {
            font-size: 18px;
            line-height: 36px;
            color: #999999;

            b {
                color: #333;
                font-weight: normal;
            }
        }

        div {
            color: #3b77e7;
            font-size: 20px;
            cursor: pointer;

            i {
                display: block;
                width: 24px;
                height: 24px;
                text-align: center;
                line-height: 24px;
                margin-right: 5px;
                border-radius: 100%;
                color: #fff;
                font-size: 14px;
                background-color: #3b77e7;
            }

        }
    }

    .add-info {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 40px 25px;
        min-height: 800px;

        span {
            color: #000000;
            font-size: 24px;
        }

        .classify-title {
            color: #333333;
            font-weight: bold;
            font-size: 20px;
            border-bottom: 1px solid #e1e1e1;
            padding: 10px 20px;
            margin-bottom: 20px;
        }

        .form {
            width: 60%;

            .submit {
                margin-top: 30px;
                align-self: center;
                width: 40%;
            }
        }
    }
}
</style>